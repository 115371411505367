import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";

const MainWrapper = styled.div`
  width: 100% !important;
  line-height: 76px;
  position: fixed;
  top: 0px;
  z-index: 1000;
  max-width: 768px !important;
`;

const HeaderText = styled.div`
  text-align: center;
  font-size: 16px;
  color: #ffffff;
`;

const ImageWrapper = styled.div`
  span {
    margin-left: 10px;
    cursor: pointer;
  }
`;

const HeaderSpace = styled.div`
  height: 70px;
`;

const AppHeaderTwinApp = props => {
  const handleBack = () => {
    if (props.backUrl && props.backUrl !== "mobile_back") {
      navigate(props.backUrl);
    } else if (props.backUrl && props.backUrl === "mobile_back") {
      // handle events for mobile app
      if (window.twinApp) {
        let data = JSON.stringify({ action: "app_back" });
        window.twinApp.postMessage(data);
      } else if (window.webkit) {
        window.webkit.messageHandlers.twinApp.postMessage({
          action: "app_back",
        });
      } else {
        console.log("Back from twin app page");
      }
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <MainWrapper
        className={`container ${
          props.bgColor ? `${props.bgColor}` : "bgWhiteColor"
        }`}
      >
        <div className="d-flex justify-content-between align-items-center">
          <ImageWrapper>
            {props.isBack ? (
              <span
                onClick={() => handleBack()}
                role="presentation"
                className={`icon-back`}
                style={{ fontSize: "16px" }}
              ></span>
            ) : (
              <div style={{ width: "30px" }}></div>
            )}
          </ImageWrapper>
          <HeaderText>{props.text}</HeaderText>
          <div style={{ width: "30px" }}></div>
        </div>
      </MainWrapper>

      <HeaderSpace></HeaderSpace>
    </>
  );
};

export default AppHeaderTwinApp;
