import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { debounce } from "lodash";
import { Link } from "gatsby";

// img
import rightArrow from "../../../images/doctor/rightArrow.svg";

// styles
import * as styles from "./DoctorsList.module.css";

const DoctorsList = props => {
  const [doctorsList, setDoctorsList] = useState({});
  const [allDoctors, setAllDoctors] = useState({}); // eslint-disable-line no-unused-vars
  const [value, setValue] = useState(false); // to change classname of a div for css

  useEffect(() => {
    if (props.doctorList) {
      setDoctorsList(props.doctorList);
      setAllDoctors(props.doctorList);
    }
  }, [props.doctorList]);

  const handleSearch = debounce(value => {
    const newValue = value.trim();
    if (newValue) {
      const newList = doctorsList.filter(item => {
        return (
          item.name.toLowerCase().includes(newValue.toLowerCase()) ||
          item.city.toLowerCase().includes(newValue.toLowerCase())
        );
      });
      setDoctorsList(newList);
      setValue(true);
    } else {
      // restore
      setDoctorsList(allDoctors);
      setValue(false);
    }
  }, 300);

  const totalDoctors = allDoctors.length;

  return (
    <div className="container twinContainer">
      <div className={value ? styles.twinDocLst_sec_1 : styles.twinDocLst_sec}>
        <div className="row">
          {/* heading */}
          <div
            className={`col-12 col-xs-12 col-md-6 col-lg-6 ${styles.twinDocLst_hdnSec}`}
          >
            {totalDoctors > 0 && <h3>Meet our {totalDoctors} doctors</h3>}

            <div className={styles.twinDocLst_line1}></div>
          </div>
          {/* search-i/p box */}
          <div
            className={`col-12 col-xs-12 col-md-6 col-lg-6 ${styles.twinDocLst_inpSec}`}
          >
            <input
              type="text"
              placeholder="Search doctors in your city"
              onChange={e => handleSearch(e.target.value)}
            ></input>
            <div className={styles.twinDocLst_line2}></div>
          </div>
        </div>
        {/* DOCTORS-LIST */}
        {props.loading ? (
          <div
            className="d-block text-center"
            style={{ height: "95vh", margin: "20% auto" }}
          >
            <Spinner animation="grow" role="status" />
          </div>
        ) : (
          <div className="row">
            {doctorsList.length === 0 && (
              <div
                className={`text-center pt-4 pb-5 ${styles.twinDoc_noDocSec}`}
              >
                No Doctor Available
              </div>
            )}
            {doctorsList.length > 0 &&
              doctorsList.map((data, index) => {
                return (
                  <div
                    className={`col-12 col-xs-12 col-md-6 col-lg-6  ${styles.listSpacing}`}
                    key={index}
                  >
                    <Link
                      to={`/twin-doctor-details/?id=${
                        data.id
                      }&total=${totalDoctors}${
                        props.twinApp &&
                        props.twinApp === "yes" &&
                        "&twinApp=yes"
                          ? `&appHeader=${props.appHeader}&twinApp=${props.twinApp}`
                          : ""
                      }`}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        className="row d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                      >
                        {/* doc-yoe */}
                        <div className={`col-2 ${styles.twinDocLst_docYoeSec}`}>
                          <p>{data.experience} yrs of exp</p>
                        </div>
                        {/* doc-image */}
                        <div className={`col-3 ${styles.twinDocLst_docImgSec}`}>
                          <img
                            src={data.profilePhoto}
                            alt=""
                            placeholder="blurred"
                            style={{
                              borderRadius: "55px",
                            }}
                          />
                        </div>
                        {/* doc-details */}
                        <div
                          className={`col-6 col-md-7 ${styles.twinDocLst_docDtlsSec}`}
                        >
                          <h3>{data.name}</h3>
                          <p>
                            {data.designation}, Twin Health, {data.city}
                          </p>
                        </div>
                        <div className="col-1 col-md-0">
                          <img
                            src={rightArrow}
                            alt=""
                            className={styles.rightIcon}
                          />
                        </div>
                      </div>
                    </Link>
                    <div className={styles.twinDocLst_line3}></div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default DoctorsList;
