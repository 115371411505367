import React, { useEffect, useState } from "react";
import axios from "axios";

// seo
import Seo from "../components/Seo";

// queryString
import queryString from "query-string";

// config
import config from "../config";

// App Header
import AppHeaderTwinApp from "../components/AppHeader/AppHeaderTwinApp";

// components
import DoctorsList from "../components/TwinDoctors/DoctorsList/DoctorsList";

const TwinDoctorsPage = props => {
  const [doctorList, setDoctorList] = useState(null);
  const [loading, setLoading] = useState(false);
  const { appHeader, twinApp } = queryString.parse(props.location.search);

  // -------> GET LIST OF DOCTORS <-------
  useEffect(() => {
    const getDoctorList = async () => {
      const doctorOptions = {
        method: "get",
        url:
          config.DX_API_BASE_URL +
          "/api/doctor?pageSize=100&page=1&filter[enabled]=1",
      };
      try {
        setLoading(true);
        const response = await axios(doctorOptions);
        setDoctorList(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error in fetching doctors list", error);
      }
    };
    getDoctorList();
  }, []);

  return (
    <div className="bgLightgray">
      <Seo
        title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
        description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
      />

      {/* Start ---> Header for Twin App */}
      {appHeader === "no" && twinApp === "yes" && (
        <AppHeaderTwinApp
          text=""
          isBack={true}
          backUrl="mobile_back"
          bgColor="bgLightgray"
        />
      )}

      <DoctorsList
        twinApp={twinApp}
        appHeader={appHeader}
        doctorList={doctorList}
        loading={loading}
      />
    </div>
  );
};

export default TwinDoctorsPage;
