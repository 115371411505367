// extracted by mini-css-extract-plugin
export var listSpacing = "DoctorsList-module--listSpacing--zPz-7";
export var rightIcon = "DoctorsList-module--rightIcon--+B6bi";
export var twinDocLst_docDtlsSec = "DoctorsList-module--twinDocLst_docDtlsSec--E9RCU";
export var twinDocLst_docImgSec = "DoctorsList-module--twinDocLst_docImgSec--iAv9u";
export var twinDocLst_docYoeSec = "DoctorsList-module--twinDocLst_docYoeSec--Bdau0";
export var twinDocLst_hdnSec = "DoctorsList-module--twinDocLst_hdnSec--UOs75";
export var twinDocLst_inpSec = "DoctorsList-module--twinDocLst_inpSec--cSbcg";
export var twinDocLst_line1 = "DoctorsList-module--twinDocLst_line1--6KOgT";
export var twinDocLst_line2 = "DoctorsList-module--twinDocLst_line2--bgng0";
export var twinDocLst_line3 = "DoctorsList-module--twinDocLst_line3--BrstM";
export var twinDocLst_sec = "DoctorsList-module--twinDocLst_sec--9xRjn";
export var twinDocLst_sec_1 = "DoctorsList-module--twinDocLst_sec_1--J2b8j";
export var twinDoc_noDocSec = "DoctorsList-module--twinDoc_noDocSec--ZMfTD";